import i18n from "i18n-js";

import zh from "../translations/zh.json";
import en from "../translations/en.json";
import jp from "../translations/jp.json";
import zht from "../translations/zht.json";

import az from "../translations/az.json";
import bd from "../translations/bd.json";
import In from "../translations/in.json";
import kh from "../translations/kh.json";
import mg from "../translations/mg.json";
import mm from "../translations/mm.json";
import ng from "../translations/ng.json";
import ph from "../translations/ph.json";
import th from "../translations/th.json";

//设置成默认en
i18n.defaultLocale = "en";
i18n.locale = "en";

i18n.fallbacks = true;
i18n.translations = {
  zh,
  en,
  jp,
  zht,
  az,
  bd,
  in: In,
  kh,
  mg,
  mm,
  ng,
  ph,
  th
};

export function changeLang(locale) {
  i18n.defaultLocale = locale;
  i18n.locale = locale;
}

export default i18n;
