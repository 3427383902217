export const countryCodeObject = {
    CN: "zh",
    HK: "zht",
    TW: "zht",
    MO: "zht",
    JP: "jp",
    AZ: "az",
    BD: "bd",
    IN: "in",
    KH: "kh",
    MG: "mg",
    MM: "mm",
    NG: "ng",
    PH: "ph",
    TH: "th"
}