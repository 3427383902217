export const Topping = {
  enable: false,
  id: 1,
  key: "Fireboy-and-Watergirl-1-Forest-Temple",
  name: "Fireboy And Watergirl 1 Forest Temple",
  src: "https://html5.gamedistribution.com/a55c9cc9c21e4fc683c8c6857f3d0c75/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/game-fireboy-and-watergirl-1-in-the-forest-temple/",
};
export const list = [
  {
    id: 1,
    key: "Fireboy-and-Watergirl-1-Forest-Temple",
    name: "Fireboy And Watergirl 1 Forest Temple",
    src: "https://html5.gamedistribution.com/a55c9cc9c21e4fc683c8c6857f3d0c75/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/game-fireboy-and-watergirl-1-in-the-forest-temple/",
  },
  {
    id: 2,
    key: "stickman-hook-game",
    name: "Stickman Hook Game",
    src: "https://html5.gamedistribution.com/599e07f9ed074845aa099aed24b9006c/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/stickman-hook-game/",
  },
  {
    id: 3,
    key: "murder-mafia",
    name: "Murder Mafia",
    src: "https://html5.gamedistribution.com/9c0c9e66a2df431d85f12bf8c8319f06/?gd_sdk_referrer_url=https://unblocked-games-6x.stickmanhook.app/murder-mafia/",
  },
  {
    id: 4,
    key: "vex-8",
    name: "Vex 8",
    src: "https://html5.gamedistribution.com/949009d40a2846f89f887de2285d6c28/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/vex-8/",
  },
  {
    id: 5,
    key: "counter-craft-2-zombies",
    name: "Counter Craft 2 Zombies",
    src: "https://html5.gamedistribution.com/3039c196e6ed42daa29fbadc69aacb04/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/counter-craft-2-zombies/",
  },
  {
    id: 6,
    key: "halloween-makeup-trends",
    name: "Halloween Makeup Trends",
    src: "https://html5.gamedistribution.com/c3aea97374024caf809f848b7fcefa01/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/halloween-makeup-trends/",
  },
  {
    id: 7,
    key: "sniper-combat-3d",
    name: "Sniper Combat 3D",
    src: "https://html5.gamedistribution.com/3751d5ed0f6b4b8f9331f148f9af52b2/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/sniper-combat-3d/",
  },
  {
    id: 8,
    key: "l-o-l-surprise-o-m-g-style-studio",
    name: "L.O.L. Surprise! O.M.G.™ Style Studio",
    src: "https://html5.gamedistribution.com/064f664fad334b2fa76d5804291b3cd4/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/l-o-l-surprise-o-m-g-style-studio/",
  },
  {
    id: 9,
    key: "makeup-studio-halloween",
    name: "Makeup Studio Halloween",
    src: "https://html5.gamedistribution.com/c2b109375d7e482cb5769babf7043164/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/makeup-studio-halloween/",
  },
  {
    id: 10,
    key: "parking-way",
    name: "Parking Way",
    src: "https://html5.gamedistribution.com/55e456136b0643c09f5f6ab23cbe9572/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/parking-way/",
  },
  {
    id: 11,
    key: "penalty-shooters-3",
    name: "Penalty Shooters 3",
    src: "https://html5.gamedistribution.com/ac793d41fb5845e59afaad6292e141e1/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/penalty-shooters-3/",
  },
  {
    id: 12,
    key: "pool-party-3",
    name: "Pool Party 3",
    src: "https://html5.gamedistribution.com/bbc832f9549140ccb6ca2f0b8ac8efab/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/pool-party-3/",
  },
  {
    id: 13,
    key: "3d-car-simulator",
    name: "3D Car Simulator",
    src: "https://html5.gamedistribution.com/55227c20a0a2476a80b8d529e5b1d1c1/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/3d-car-simulator//",
  },
  {
    id: 14,
    key: "rescue-girl",
    name: "Rescue Girl",
    src: "https://html5.gamedistribution.com/3e2d1cae272d437a9c4b552d1cbf18da/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/rescue-girl//",
  },
  {
    id: 15,
    key: "organization-princess",
    name: "Organization Princess",
    src: "https://html5.gamedistribution.com/d445590892294eebb41d983e1c598ab1/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/organization-princess//",
  },
  {
    id: 16,
    key: "bike-racing",
    name: "Bike Racing",
    src: "https://html5.gamedistribution.com/fab6088a4a1b4785aa78fb303de1b155/?gd_sdk_referrer_url=https://unblocked-games-6x.stickmanhook.app/bike-racing//",
  },
  {
    id: 17,
    key: "merge-gangster-heist-VI",
    name: "Merge Gangster Heist VI",
    src: "https://html5.gamedistribution.com/350cf59fde7443e28c68e3205949ab24/?gd_sdk_referrer_url=https://xptcsggyw04.fireboywatergirl.pro/merge-gangster-heist-VI//",
  },
];
