import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

function Header() {
  const domain = window.location.origin;
  return (
    // <div className="header">
    //   <Link to="/">{domain}</Link>
    // </div>
    <></>
  );
}

export default Header;
