import React from "react";
import t from "@/utils/i18n";

function Description(props) {
  const { id } = props;
  if (id === "stickman-hook-game") {
    return (
      <>
        <h2>{t.t(`${id}.d1`)}</h2>
        <ul>
          <li>{t.t(`${id}.d2`)}</li>
          <li>{t.t(`${id}.d3`)}</li>
          <li>{t.t(`${id}.d4`)}</li>
          <li>{t.t(`${id}.d5`)}</li>
        </ul>
        <h2>{t.t(`${id}.d6`)}</h2>
        <ul>
          <li>{t.t(`${id}.d7`)}</li>
          <li>{t.t(`${id}.d8`)}</li>
          <li>{t.t(`${id}.d9`)}</li>
        </ul>
        <p>{t.t(`${id}.d10`)}</p>
      </>
    );
  }
  if (id === "murder-mafia") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <p>{t.t(`${id}.d2`)}</p>
        <h2>{t.t(`${id}.d3`)}</h2>
        <div>{t.t(`${id}.d4`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d5`)}</h2>
        <div>{t.t(`${id}.d6`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d7`)}</h2>
        <ul>
          <li>{t.t(`${id}.d8`)}</li>
          <li>{t.t(`${id}.d9`)}</li>
          <li>{t.t(`${id}.d10`)}</li>
        </ul>
        <h2>{t.t(`${id}.d11`)}</h2>
        <div>{t.t(`${id}.d12`)}</div>
      </>
    );
  }
  if (id === "vex-8") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <h2>{t.t(`${id}.d2`)}</h2>
        <div>{t.t(`${id}.d3`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d4`)}</h2>
        <div>{t.t(`${id}.d5`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d6`)}</h2>
        <div>{t.t(`${id}.d7`)}</div>
      </>
    );
  }
  if (id === "counter-craft-2-zombies") {
    return (
      <>
        <h2>{t.t(`${id}.d1`)}</h2>
        <div>{t.t(`${id}.d2`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d3`)}</h2>
        <div>{t.t(`${id}.d4`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d5`)}</h2>
        <div>{t.t(`${id}.d6`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d7`)}</h2>
        <ul>
          <li>{t.t(`${id}.d8`)}</li>
          <li>{t.t(`${id}.d9`)}</li>
          <li>{t.t(`${id}.d10`)}</li>
          <li>{t.t(`${id}.d11`)}</li>
        </ul>
      </>
    );
  }

  if (id === "halloween-makeup-trends") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <h2>{t.t(`${id}.d2`)}</h2>
        <div>{t.t(`${id}.d3`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d4`)}</h2>
        <div>{t.t(`${id}.d5`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d6`)}</h2>
        <div>{t.t(`${id}.d7`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d8`)}</h2>
        <div>{t.t(`${id}.d9`)}</div>
      </>
    );
  }

  if (id === "sniper-combat-3d") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <p>{t.t(`${id}.d2`)}</p>
        <p>{t.t(`${id}.d3`)}</p>
        <p>{t.t(`${id}.d4`)}</p>
        <ul>
          <li>{t.t(`${id}.d5`)}</li>
          <li>{t.t(`${id}.d6`)}</li>
          <li>{t.t(`${id}.d7`)}</li>
          <li>{t.t(`${id}.d8`)}</li>
          <li>{t.t(`${id}.d9`)}</li>
        </ul>
        <p>{t.t(`${id}.d10`)}</p>
      </>
    );
  }

  if (id === "l-o-l-surprise-o-m-g-style-studio") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <p>{t.t(`${id}.d2`)}</p>
        <h2>{t.t(`${id}.d3`)}</h2>
        <div>{t.t(`${id}.d4`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d5`)}</h2>
        <div>{t.t(`${id}.d6`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d7`)}</h2>
        <div>{t.t(`${id}.d8`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d9`)}</h2>
        <div>{t.t(`${id}.d10`)}</div>
      </>
    );
  }

  if (id === "makeup-studio-halloween") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <h2>{t.t(`${id}.d2`)}</h2>
        <div>{t.t(`${id}.d3`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d4`)}</h2>
        <div>{t.t(`${id}.d5`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d6`)}</h2>
        <div>{t.t(`${id}.d7`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d8`)}</h2>
        <div>{t.t(`${id}.d9`)}</div>
      </>
    );
  }

  if (id === "parking-way") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <p>{t.t(`${id}.d2`)}</p>
        <p>{t.t(`${id}.d3`)}</p>
        <h2>{t.t(`${id}.d4`)}</h2>
        <div>{t.t(`${id}.d5`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d6`)}</h2>
        <div>{t.t(`${id}.d7`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d8`)}</h2>
        <ul>
          <li>{t.t(`${id}.d9`)}</li>
          <li>{t.t(`${id}.d10`)}</li>
          <li>{t.t(`${id}.d11`)}</li>
          <li>{t.t(`${id}.d12`)}</li>
          <li>{t.t(`${id}.d13`)}</li>
        </ul>
      </>
    );
  }

  if (id === "penalty-shooters-3") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <h2>{t.t(`${id}.d2`)}</h2>
        <div>{t.t(`${id}.d3`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d4`)}</h2>
        <div>{t.t(`${id}.d5`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d6`)}</h2>
        <div>{t.t(`${id}.d7`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d8`)}</h2>
        <div>{t.t(`${id}.d9`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d10`)}</h2>
        <ul>
          <li>{t.t(`${id}.d11`)}</li>
          <li>{t.t(`${id}.d12`)}</li>
          <li>{t.t(`${id}.d13`)}</li>
          <li>{t.t(`${id}.d14`)}</li>
        </ul>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d15`)}</h2>
        <ul>
          <li>{t.t(`${id}.d16`)}</li>
          <li>{t.t(`${id}.d17`)}</li>
        </ul>
        <p>{t.t(`${id}.d18`)}</p>
      </>
    );
  }

  if (id === "pool-party-3") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <p>{t.t(`${id}.d2`)}</p>
        <p>{t.t(`${id}.d3`)}</p>
        <h2>{t.t(`${id}.d4`)}</h2>
        <div>{t.t(`${id}.d5`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d6`)}</h2>
        <div>{t.t(`${id}.d7`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d8`)}</h2>
        <ul>
          <li>{t.t(`${id}.d9`)}</li>
          <li>{t.t(`${id}.d10`)}</li>
          <li>{t.t(`${id}.d11`)}</li>
          <li>{t.t(`${id}.d12`)}</li>
          <li>{t.t(`${id}.d13`)}</li>
        </ul>
      </>
    );
  }

  if (id === "3d-car-simulator") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <h2>{t.t(`${id}.d2`)}</h2>
        <div>{t.t(`${id}.d3`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d4`)}</h2>
        <div>{t.t(`${id}.d5`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d6`)}</h2>
        <ul>
          <li>{t.t(`${id}.d7`)}</li>
          <li>{t.t(`${id}.d8`)}</li>
          <li>{t.t(`${id}.d9`)}</li>
          <li>{t.t(`${id}.d10`)}</li>
          <li>{t.t(`${id}.d11`)}</li>
        </ul>
      </>
    );
  }

  if (id === "rescue-girl") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <h2>{t.t(`${id}.d2`)}</h2>
        <div>{t.t(`${id}.d3`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d4`)}</h2>
        <div>{t.t(`${id}.d5`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d6`)}</h2>
        <ul>
          <li>{t.t(`${id}.d7`)}</li>
          <li>{t.t(`${id}.d8`)}</li>
          <li>{t.t(`${id}.d9`)}</li>
        </ul>
      </>
    );
  }

  if (id === "organization-princess") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <h2>{t.t(`${id}.d2`)}</h2>
        <div>{t.t(`${id}.d3`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d4`)}</h2>
        <ul>
          <li>{t.t(`${id}.d5`)}</li>
          <li>{t.t(`${id}.d6`)}</li>
          <li>{t.t(`${id}.d7`)}</li>
          <li>{t.t(`${id}.d8`)}</li>
          <li>{t.t(`${id}.d9`)}</li>
          <li>{t.t(`${id}.d10`)}</li>
        </ul>
        <h2>{t.t(`${id}.d11`)}</h2>
        <div>{t.t(`${id}.d12`)}</div>
      </>
    );
  }

  if (id === "bike-racing") {
    return (
      <>
        <p>{t.t(`${id}.d1`)}</p>
        <h2>{t.t(`${id}.d2`)}</h2>
        <div>{t.t(`${id}.d3`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d4`)}</h2>
        <div>{t.t(`${id}.d5`)}</div>
        <div>
          <br />
        </div>
        <h2>{t.t(`${id}.d6`)}</h2>
        <ul>
          <li>{t.t(`${id}.d7`)}</li>
          <li>{t.t(`${id}.d8`)}</li>
          <li>{t.t(`${id}.d9`)}</li>
          <li>{t.t(`${id}.d10`)}</li>
        </ul>
        <h2>{t.t(`${id}.d11`)}</h2>
        <div>{t.t(`${id}.d12`)}</div>
      </>
    );
  }

  if (id === "Fireboy-and-Watergirl-1-Forest-Temple") {
    return (
      <>
        <h2>{t.t(`${id}.d1`)}</h2>
        <ul>
          <li>{t.t(`${id}.d2`)}</li>
          <ul>
            <li>{t.t(`${id}.d3`)}</li>
            <li>{t.t(`${id}.d4`)}</li>
          </ul>
        </ul>
        <ul>
          <li>{t.t(`${id}.d5`)}</li>
          <ul>
            <li>{t.t(`${id}.d6`)}</li>
            <li>{t.t(`${id}.d7`)}</li>
          </ul>
        </ul>
        <h2>{t.t(`${id}.d8`)}</h2>
        <ul>
          <li>{t.t(`${id}.d9`)}</li>
          <li>{t.t(`${id}.d10`)}</li>
          <li>{t.t(`${id}.d11`)}</li>
          <li>{t.t(`${id}.d12`)}</li>
        </ul>
      </>
    );
  }

  return (
    <>
      <p>{t.t(`${id}.d1`)}</p>
      <p>{t.t(`${id}.d2`)}</p>
      <p>{t.t(`${id}.d3`)}</p>
      <h2>{t.t(`${id}.d4`)}</h2>
      <div>{t.t(`${id}.d5`)}</div>
      <div>
        <br />
      </div>
      <h2>{t.t(`${id}.d6`)}</h2>
      <div>{t.t(`${id}.d7`)}</div>
      <div>
        <br />
      </div>
      <h2>{t.t(`${id}.d8`)}</h2>
      <ul>
        <li>{t.t(`${id}.d9`)}</li>
        <li>{t.t(`${id}.d10`)}</li>
        <li>{t.t(`${id}.d11`)}</li>
      </ul>
    </>
  );
}

export default Description;
