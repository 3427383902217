import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../pages/Home/Home";
import Detail from "../pages/Detail/Detail";
import NoMatch from "../pages/404/404";

const BasicRoute = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/:id" children={<Detail />} />
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  </Router>
);

export default BasicRoute;
